@import '../../jomalone2_base/scss/jomalone2-base';
// Locale change overlay

@import 'sections/locale_interstitial';
@import 'sections/all';

.sd-product-grid {
  .elc-product-price-row-wrapper,
  .elc-product-product-name-wrapper {
    order: 1;
    height: 45px;
  }
  .elc-product-size-wrapper {
    order: 2;
  }
  .elc-product-tax-included-label-wrapper {
    color: $color-primary-600;
  }
}

.elc-product-full {
  .elc-product-tax-included-wrapper {
    display: grid;
  }
  .elc-product-tax-included-label-wrapper {
    order: 2;
    line-height: 1;
    padding-#{$ldirection}: 16px;
  }
  .elc-find-in-store {
    width: auto;
    .elc-findinstore-button {
      height: auto;
    }
  }
  .elc-grid-container {
    .elc-find-in-store {
      display: block;
    }
  }
  .elc-right-column-section {
    .elc-bopis-wrapper {
      border: none;
    }
  }
}

.elc-responsive-modal-mask {
  &.isOpen {
    .elc-product-quick-view-wrapper {
      min-height: 67%;
      height: auto;
    }
  }
  .elc-product-notify-me-pop-up-wrapper {
    .elc-product-notify-me-content {
      .elc-checkbox {
        padding-bottom: 20px;
      }
      .elc-checkbox-label {
        padding-#{$ldirection}: 0;
        vertical-align: text-bottom;
      }
      .elc-product-notify-me-submit-button {
        margin-top: 0;
      }
    }
  }
}

.elc-size-picker-container,
.elc-product-prices-wrapper,
.elc-product-prices-clickable-wrapper,
.elc-quickview-content-prices-wrapper {
  .elc-product-tax-included-label-wrapper {
    margin-#{$rdirection}: 5px;
  }
}

.gnav-section-group {
  .gnav-section-formatter--grid-1 {
    &.gnav-section-formatter--tout-enabled {
      .gnav-section-formatter__gnav-links-wrapper {
        & > div {
          &:nth-child(6) {
            @include breakpoint($landscape-up) {
              margin-#{$rdirection}: 3.57%;
            }
          }
        }
      }
    }
  }
}
