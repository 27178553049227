.locale-overlay {
  background: $color-grey-transparent-70;
  top: 0;
  #{$ldirection}: 0;
  width: 100%;
  height: 100%;
  z-index: 1100;
  display: none;
  position: fixed;
  .locale-interstitial {
    position: fixed;
    width: 94%;
    #{$ldirection}: 3%;
    top: 12%;
    margin: auto;
    padding: 2px;
    background: $white;
    line-height: $base-line-height;
    text-align: center;
    border: 3px solid $color-black;
    @include breakpoint($large-up) {
      width: 80%;
      max-width: 800px;
      position: relative;
      #{$ldirection}: 0;
    }
    .inner-border {
      border: 1px solid $color-black;
      padding: 3em 2em;
    }
    .initial-hide {
      display: none;
      margin-bottom: 5px;
      .language-label {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
    .welcome-label {
      margin-bottom: 15px;
      font-size: 25px;
    }
    .choose-country {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .country-list {
      width: 100%;
      padding: 0;
      list-style-type: none;
      .country {
        width: 33%;
        display: inline-block;
        float: none;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          width: 25%;
        }
        .country-link {
          display: inline-block;
          line-height: 2.2;
          text-decoration: none;
          &:hover {
            text-decoration: none;
          }
          &.selected {
            font-weight: bold;
          }
        }
      }
    }
    .language-list {
      float: #{$ldirection};
      @include swap_direction(margin, 0 1em 0 0);
      list-style: none;
      padding: 0;
      list-style-type: none;
      width: 100%;
      line-height: 2.2;
      &__item {
        width: 33%;
        padding: 0;
        display: inline-block;
        float: none;
        text-align: center;
        font-size: 12px;
        text-transform: uppercase;
        @include breakpoint($large-up) {
          width: 25%;
        }
        &.selected {
          font-weight: bold;
        }
      }
      &__select {
        opacity: 1;
        margin: 0;
        position: relative;
        #{$ldirection}: inherit;
      }
    }
    .country-list-offsite {
      width: 100%;
      padding: 0;
      list-style-type: none;
    }
    .choose-locale-button {
      text-transform: uppercase;
      color: $color-white;
      padding: 0 2em;
      background: $color-black;
    }
  }
}
