.lp_radio_button {
  input[type='radio'] {
    ~ label {
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.gnav-header-block {
  &__utilities {
    .gnav-util {
      &__icon {
        &__cart-count {
          width: 12px;
        }
      }
    }
  }
  .gnav-header-sections {
    &__section-label {
      @include breakpoint($xlarge-up) {
        @include swap_direction(margin, 0 40px 0 0);
      }
    }
  }
}

.show-results {
  .quiz-page-formatter {
    &__main-block {
      display: inline-block;
      @include breakpoint($large-up) {
        display: flex;
      }
    }
  }
}

.site-footer {
  .sitewide-footer-formatter {
    &__content {
      @include breakpoint($large-up) {
        flex: 0 59%;
      }
    }
    &__top {
      @include breakpoint($large-up) {
        flex: 0 41%;
      }
    }
  }
}

.waitlist-signup-container {
  .waitlist-signup-block {
    .waitlist_signup_fields {
      .waitlist_signup {
        .form-submit {
          padding: 24px;
        }
      }
    }
  }
}

.sd-search-results {
  .elc-price-and-size {
    display: block;
  }
}

.checkout {
  .signin-block {
    .gender_container {
      label {
        display: block;
      }
    }
  }
}

body {
  .gender_container {
    label {
      color: $black;
      font-size: 12px;
    }
    input[type='radio'] + label {
      font-size: 11px;
    }
  }
}

.signin {
  .new-account {
    .form-item {
      .gender_container {
        label {
          font-size: 12px;
        }
        input[type='radio'] {
          & + label {
            font-size: 11px;
            padding-#{$ldirection}: 28px;
            &::before {
              top: 8px;
            }
          }
          &:checked + label::after {
            top: 13px;
          }
        }
      }
    }
  }
  .checkout {
    .site-footer {
      @include breakpoint($medium-up) {
        position: static;
      }
    }
  }
}
